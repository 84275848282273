import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './Core/store/app-context';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { lazy } from 'react';

const container = document.getElementById('root');
const root = createRoot(container);

const baseURL = window.REACT_APP_API_ENDPOINT;

// https://www.pluralsight.com/guides/how-to-load-components-conditionally-in-reactjs
const renderApp = () => {
  console.log({baseURL})
  switch(true){
    case baseURL?.includes('lms'):
      console.log('APPJS: LMS') 
      const AppLMS = lazy(() => import("./AppLMS"));
      return <AppLMS />
    case baseURL?.includes('sd'): 
      console.log('APPJS: SD') 
      const AppSD = lazy(() => import("./AppSD"));
      return <AppSD />
    default:
      console.log('APPJS: LMS') 
      const AppDefault = lazy(() => import("./AppLMS"));
      return <AppDefault />
  }
}

root.render(
  <AppProvider>
    {renderApp()}
  </AppProvider>
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
