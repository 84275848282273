export const ONGOING = 20;
export const COMPLETED = 80;
export const RECEIVED = 80;
export const PLANNED = 10;
export const CANCELED = 99;
// export const CLOSED = 'Closed';
export const PICKLIST_COMPLETED = 15;
export const REVIEW_PENDING = 30;
export const RECOUNT_OPEN = 40;
export const RECOUNT_REVIEW_PENDING = 60;
export const ACTIVE = 1;
export const INACTIVE = 0;
export const IN_USE = 2;
export const AVAILABLE = 1;

export const SUCCESS = 1;
export const ERROR = 2;
export const WARNING = 3;
export const INFO = 4;
