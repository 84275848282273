import message from 'antd/es/message';
import moment from 'moment';
import {
  COMPLETED,
  CANCELED,
  ONGOING,
  PICKLIST_COMPLETED,
  PLANNED,
  RECEIVED,
  REVIEW_PENDING,
  RECOUNT_OPEN,
  RECOUNT_REVIEW_PENDING,
  ACTIVE,
  INACTIVE,
  AVAILABLE,
  IN_USE,
} from '../constants';

export const GetMomentDateObj = (date) => {
  let parsedDate = moment(date);
  return parsedDate;
};

export const FormatDate = (text, value) => {
  if (text) {
    return moment(text).format('MMM DD, YYYY, HH:mm A');
  } else {
    return value;
  }
};

export const FormatDateLocal = (text, value) => {
  if (text) {
    return moment
      .utc(text)
      .utcOffset(moment().utcOffset())
      .format('MMM DD, YYYY, hh:mm A');
  } else {
    return value;
  }
};

export const GetBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = '';
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    if (file && file.type.match('image.*')) {
      reader?.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    }
  });
};

export const GetFirstChar = (value) => {
  if (!value) {
    return;
  }
  let splittedWords = value.split(' ');
  let initials = '';
  splittedWords.forEach((x) => {
    initials = initials + x.charAt(0);
  });
  return initials.slice(0, 3);
};

/*

To validate file 
params
- ctx = Context
- file = file to validate
- maxSize = Number - max size of the file to allow in MB
- allowedFiles = Array - check the file extension - default is set to jpg, jpeg, png, bmp, gif

*/

export const ValidateFile = (
  ctx,
  file,
  maxSize = process.env.REACT_APP_MAX_UPLOAD_SIZE,
  allowedFiles = ['jpg', 'png', 'jpeg', 'bmp', 'gif']
) => {
  const fileSize = file?.size / 1024 / 1024;
  const regex = new RegExp(allowedFiles.join('|'), 'gi');
  if (!file?.name.match(regex)) {
    ctx.showToastAlert({
      type: 'error',
      message: `You can only upload ${allowedFiles.join(', ')} files.`,
    });
    return false;
  } else if (fileSize > maxSize) {
    ctx.showToastAlert({
      type: 'error',
      message: `You can upload image up to size ${maxSize}MB.`,
    });
    return false;
  }
  return true;
};

// export const FirstLetterUppercase = (value) => {
//   if (value) {
//     let lowerCase = value.toLowerCase();
//     return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
//   }
// };

export const ReplaceCharToStrigify = (data) => {
  let value = data.replace(/\r?\n?/g, '');
  if (isJson(value)) {
    let parsedValue = JSON.parse(value);
    return (
      <pre className="custom-scrollbar font-semibold text-h1 font-poppins mb-1">
        {JSON.stringify(parsedValue, null, 2)}
      </pre>
    );
  } else {
    return (
      <pre className="custom-scrollbar font-semibold text-h1 font-poppins mb-1">
        {data}
      </pre>
    );
  }
};

function isJson(object) {
  try {
    JSON.parse(object);
  } catch (e) {
    return false;
  }
  return true;
}

export const DownloadFileBlob = (fileApiRes, fileName) => {
  const url = window.URL.createObjectURL(new Blob([fileApiRes.data]));
  var a = document.createElement('a'); //Create <a>
  a.href = url;
  if (fileApiRes.request.getResponseHeader('Content-Disposition'))
    // check if server sends exposed Content Disposition header and fetch filename from there.
    a.download = fileApiRes.request
      .getResponseHeader('Content-Disposition')
      .split(';')[1]
      .split('=')[1]
      .replaceAll('"', '');
  else a.download = fileName; //File name Here
  document.body.appendChild(a);
  a.click(); //Downloaded file
  document.body.removeChild(a);
};

export const Base64URLEncode = (str) => {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

export const GetFileExtension = (fileName) => {
  var reg = /(?:\.([^.]+))?$/;
  return reg.exec(fileName)[1];
};

export const ConvertToDecimal = (value, fixed = 3) => {
  if (!value) {
    return;
  }
  let result;
  var arr = value.toString().split('.');
  if (arr.length > 1) {
    if (arr[1].length > 3) {
      result = parseFloat(value).toFixed(fixed);
    } else {
      result = parseFloat(value);
    }
  } else {
    result = parseInt(value);
  }
  return result;
};

export const ValidateEmail = (text) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(text)) {
    return true;
  }

  return false;
};

export const FirstLetterUppercase = (value) => {
  if (value) {
    let lowerCase = value.toLowerCase();
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
  }
};

const showToastAlert = (messageInfo) => {
  if (messageInfo.type === 'error') {
    message.error(
      {
        content: messageInfo.message,
        className: 'error-toast-msg',
      },
      5
    );
  } else {
    message.success(
      {
        content: messageInfo.message,
        className: 'success-toast-msg',
      },
      5
    );
  }
};

//for local sorting in tables
export const getSorter = (type, key, order = 'ASC') => {
  //pass type = false if you want to disable sorting
  if (type === false) {
    return false;
  }

  let ord = order == 'ASC' ? 1 : -1;

  if (type === 'int') {
    return (a, b) => (+a[key] - +b[key]) * ord;
  } else if (type === 'date') {
    return (a, b) =>
      (moment(a[key]).valueOf() - moment(b[key]).valueOf()) * ord;
  } else {
    return (a, b) => {
      if (a[key] > b[key]) return 1 * ord;
      else if (a[key] < b[key]) return -1 * ord;
      else return 0;
    };
  }
};

//for validating form fields
export const validateField = (obj, fieldObj) => {
  let { field, type, min, max, list, listKey, handler } = fieldObj;

  if (typeof field != 'string' || field == '') return;

  let val = obj[field];

  if (type === 'num') {
    if (typeof val != 'number') return false;

    if (val >= min && val <= max) return true;

    return false;
  } else if (type === 'select') {
    let vals = list?.map((a) => `${a[listKey]}`);
    return vals?.includes(`${val}`);
  }
  //custom validation to validate emails etc
  else if (type === 'custom') {
    return handler(val, obj);
  } else {
    if (typeof val !== 'string') return false;

    return val?.trim() !== '';
  }
};

// For Form Validation
export const handleValidation = (
  e,
  nameCheck,
  currentInputs,
  validationInputs,
  initialState,
  newState,
  isValid,
  specialCheck = false
) => {
  e.preventDefault();
  let error = initialState;
  Object.keys(validationInputs).forEach((i) => {
    if (!Object.hasOwn(currentInputs, i)) {
      error[i] = 'This field is required';
    } else {
      error[i] = null;
    }
  });
  if (nameCheck === true) {
    let allTargets = [...e.target].filter((i) => i.name !== e.target.name);
    console.log(allTargets, 'allTargets');
    let error = initialState;
    allTargets.forEach((i) => {
      if ((i.value === '' || i.value === null) && i.name !== 'false') {
        error[i.name] = 'This field is required';
      } else {
        error[i.name] = null;
      }
    });
  }
  newState({ ...error });
  console.log(error);
  let valid = Object.values(error).every((i) => i === null || i === '');
  console.log(valid);
  if (valid === true && typeof isValid === 'function') {
    isValid();
  }
};

// For Status tab colors

export const getStatusColorById = (statusId) => {
  switch (+statusId) {
    case PLANNED:
    case RECOUNT_OPEN:
    case INACTIVE:
      return 'bg-punch';
    case RECEIVED:
    case COMPLETED:
      return 'bg-tradewind';
    case ONGOING:
    case IN_USE:
      return 'bg-progress-yellow ';
    case CANCELED:
      return 'bg-regent-gray';
    case REVIEW_PENDING:
    case RECOUNT_REVIEW_PENDING:
      return 'bg-pickled-bluewood';
    case PICKLIST_COMPLETED:
      return 'bg-royal-blue';
    case ACTIVE:
    case AVAILABLE:
      return 'bg-vista-blue';
    default:
      return 'bg-black';
  }
};

// format number to currency (with commas)
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
export const formatToCurrency = (number) => formatter.format(number);

// returns array [isValidBarcode, MaterialId]
export const extractMaterialIdFromBarcode = (barcode) => {
  if (!barcode) return [false, ''];
  if (typeof barcode === 'string' && barcode.length === 0) return [false, ''];
  if (barcode.split(')').length === 1) return [false, ''];

  try {
    const isCommodity = barcode.split(')').length === 2;
    let id;
    if (isCommodity) {
      const rx = /\).*/g;
      id = rx.exec(barcode);
      id = id[0].replace(')', '');
    } else {
      const rx = /\)(.*?)\(/g;
      id = rx.exec(barcode)[1];
    }
    return [true, id];
  } catch (e) {
    return [false, ''];
  }
};

export const getTokenExpiry = () => {
  var lastLoginDate = moment
    .utc(JSON.parse(sessionStorage.getItem('userInfo'))['lastLogin'])
    .toDate();
  setInterval(() => {
    if (
      moment().local() - lastLoginDate >=
      JSON.parse(localStorage.getItem('expiry')) * 10
    ) {
      alert('SessionTimeout');
    }
    console.log(
      moment().local() - lastLoginDate,
      JSON.parse(localStorage.getItem('expiry')) * 10,
      'interval'
    );
  }, 3000);
};

export const getScreenType = () => {
  let screenType = 'xl';
  if (typeof window === 'undefined') {
    return 'undefined';
  }
  var windowWidth = window.innerWidth;
  if (windowWidth >= 1600) {
    screenType = '3xl';
  } else if (windowWidth >= 1400) {
    screenType = '2xl';
  } else if (windowWidth >= 1200) {
    screenType = 'xl';
  } else if (windowWidth >= 992) {
    screenType = 'lg';
  } else if (windowWidth >= 768) {
    screenType = 'md';
  } else {
    screenType = 'sm';
  }

  return screenType;
};

export function convertTZ(date, tzString) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    })
  );
}
