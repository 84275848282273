import Modal from 'antd/es/modal';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import moment from 'moment';
import { useState } from 'react';
import useDetectKeyboardOpen from './useKeyboardDetection';
import useInView from 'react-cool-inview';

export const CustomModal = (props) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const {
    icon,
    titleText,
    messageText,
    isLoading,
    showModal,
    showCancelButton,
    handleCancel,
    confirmButtonText,
    cancelButtonText,
    handleConfirm,
    showConfirmButton,
    zIndex,
    mobileModal,
    mobileSearch,
    listData,
    ref,
    observer,
    handleVendorSelect,
    handleTransferSelect,
    handleBatchSelect,
    disabledButton,
    searchPlaceholder,
    materialModal,
    batchModal,
    serialModal,
    list,
    index,
    parentData,
    materialPutawayModal,
    handleSearch,
    handleAdHoc,
    modalStyle,
    searchValue,
    showMaterialImage,
    modalMaterialDivHeight,
    scrollPaginationFunc,
    totalCount,
    enablePagination,
  } = props;

  const [value, setValue] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { observe } = useInView({
    // For better UX, we can grow the root margin so the data will be loaded earlier
    rootMargin: '100px 0px',
    // When the last item comes to the viewport
    threshold: 1,
    onEnter: ({ unobserve, observe }) => {
      unobserve();
      listData.length !== totalCount &&
        enablePagination &&
        scrollPaginationFunc({ search: searchValue });
      observe();
    },
  });

  return (
    <Modal
      visible={showModal}
      closable={false}
      centered={true}
      wrapClassName="rounded custom-modal pt-3 "
      footer={null}
      destroyOnClose={true}
      width={window?.innerWidth < 992 ? '85%' : '414px'}
      bodyStyle={{ width: '100%', padding: 2 }}
      zIndex={zIndex ? zIndex : 1000}
      style={{ paddingBottom: 0 }}
    >
      {mobileModal ? (
        <div
          // style={{ height: window?.innerWidth < 992 ? '82vh' : '80vh' }}
          style={{
            height: '500px',
          }}
          className="pt-0.5 overflow-y-hidden"
        >
          <div className="flex justify-between items-center my-5 mx-4 lg:mx-5">
            <p
              className="font-poppins font-normal text-xl text-regent-gray"
              style={{ fontSize: '19px' }}
            >
              {titleText}
            </p>
            <div className="h-auto flex items-center">
              <CloseCircleOutlined
                onClick={handleCancel}
                style={{ fontSize: '24px', color: '#8892A5' }}
              />
            </div>
          </div>
          {batchModal && !isKeyboardOpen && (
            <>
              <div className="flex justify-start items-center bg-athens-gray py-4">
                <div className="border border-athens-gray bg-white w-12 h-12 flex justify-center items-center rounded-md mr-2 ml-5">
                  <div className="w-10 h-auto">
                    <img
                      src={
                        parentData?.Image || parentData?.MaterialImage
                          ? `data:image/png;base64, ${
                              parentData.Image || parentData?.MaterialImage
                            }`
                          : '/images/icons/imagenotfound.jpeg'
                      }
                      loading="lazy"
                      alt="materialImg"
                      className="object object-fill w-full h-full"
                    />
                  </div>
                </div>
                <div>
                  <p className="font-poppins font-semibold text-sm text-pickled-bluewood">
                    {parentData?.MaterialName}
                  </p>
                  <p className="font-poppins font-medium text-sm text-regent-gray">
                    ID #{parentData?.MaterialId}
                  </p>
                </div>
              </div>
              <div></div>
            </>
          )}
          {mobileSearch && (
            <div className="bg-athens-gray rounded-lg p-2 mt-5 mx-4 lg:mx-5">
              <Input
                bordered={false}
                prefix={
                  <div className="h-auto flex items-center w-5">
                    <SearchOutlined
                      style={{ fontSize: '18px', color: '#8892A5' }}
                      className="w-full h-full object object-fill"
                    />
                  </div>
                }
                type="search"
                placeholder={searchPlaceholder}
                className="regent-gray-placeholder"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  handleSearch(e.target.value);
                }}
                // allowClear={searchValue ? true : false}
                allowClear
                value={searchValue}
              />
            </div>
          )}
          {list && (
            <div className="h-full overflow-y-auto mx-4 lg:mx-5">
              {list && (
                <div
                  onClick={() => {
                    handleVendorSelect(
                      list?.MaterialId,
                      list?.MaterialName,
                      list?.MaterialType,
                      null,
                      null,
                      list?.MaterialTypeId
                    );
                    handleCancel();
                  }}
                  className="p-5 border-b-2 cursor-pointer border-athens-gray"
                >
                  <p className="font-poppins font-semibold text-sm text-pickled-bluewood">
                    {list?.MaterialName}
                  </p>
                  <p className="font-poppins font-medium text-sm text-regent-gray">
                    ID #{list?.MaterialId}
                  </p>
                </div>
              )}
            </div>
          )}
          {materialModal || materialPutawayModal ? (
            <div
              className="overflow-y-auto px-4 pb-14 lg:mx-5"
              style={{
                height: modalMaterialDivHeight
                  ? modalMaterialDivHeight
                  : showCancelButton === false && showConfirmButton === false
                  ? '90%'
                  : '73vh',
              }}
            >
              {listData && listData !== null && listData.length > 0 ? (
                <>
                  {listData?.map((data, i) => (
                    <div
                      key={data.MaterialId}
                      onClick={() => {
                        if (materialPutawayModal) {
                          handleVendorSelect(data);
                        } else {
                          handleVendorSelect(
                            data?.MaterialId,
                            data?.MaterialName,
                            data?.MaterialType,
                            data?.UOM,
                            index,
                            data?.MaterialTypeId,
                            data?.AvailableQty
                          );
                        }
                        handleCancel();
                      }}
                      className="py-3 border-b-2 cursor-pointer border-athens-gray"
                    >
                      {materialPutawayModal ? (
                        <>
                          <div className="flex justify-start items-center">
                            <div className="border border-athens-gray w-12 h-12 flex justify-center items-center rounded-md mr-2">
                              <div className="w-10 h-auto">
                                <img
                                  src={
                                    data?.Image || data?.MaterialImage
                                      ? `data:image/png;base64, ${
                                          data.Image || data?.MaterialImage
                                        }`
                                      : '/images/icons/imagenotfound.jpeg'
                                  }
                                  loading="lazy"
                                  alt="materialImg"
                                  className="object object-fill w-full h-full"
                                />
                              </div>
                            </div>
                            <div>
                              <p className="font-poppins font-semibold text-sm text-daisy-bush">
                                {data?.MaterialName}
                              </p>
                              <p className="font-poppins font-medium text-sm text-regent-gray">
                                ID #{data?.MaterialId}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            ref={
                              enablePagination && i === listData?.length - 1
                                ? observe
                                : null
                            }
                            className="flex justify-start items-center"
                          >
                            {showMaterialImage && (
                              <div className="border border-athens-gray w-12 h-12 flex justify-center items-center rounded-md mr-2">
                                <div className="w-10 h-auto">
                                  <img
                                    loading="lazy"
                                    src={
                                      data?.Image || data?.MaterialImage
                                        ? `data:image/png;base64, ${
                                            data.Image || data?.MaterialImage
                                          }`
                                        : '/images/icons/imagenotfound.jpeg'
                                    }
                                    alt="materialImg"
                                    className="object object-fill w-full h-full"
                                  />
                                </div>
                              </div>
                            )}
                            <div>
                              <p className="font-poppins whitespace-normal break-all font-semibold text-sm text-daisy-bush">
                                {data?.MaterialName}
                              </p>
                              <p className="font-poppins font-medium text-sm text-regent-gray">
                                ID #{data?.MaterialId}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="flex justify-center items-center mt-4">
                    <div className="font-poppins font-semibold text-sm text-pickled-bluewood">
                      {listData && Array.isArray(listData)
                        ? 'No data available'
                        : searchTerm && searchTerm.length > 0
                        ? 'No search results found'
                        : 'Loading data. Please wait.'}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : batchModal ? (
            <div className="m-5">
              {listData && listData?.length !== 0 ? (
                <div>
                  <div className="flex justify-between">
                    <div className="font-poppins font-semibold text-xs text-regent-gray">
                      BATCH NUMBER
                    </div>
                    <div className="font-poppins font-semibold text-xs text-regent-gray">
                      REMAINING QTY.
                    </div>
                  </div>
                  <div className="w-full overflow-y-scroll h-80">
                    {listData?.map((batch) => (
                      <div key={batch.uid}>
                        {/* {console.log(listData)} */}
                        {/* <Divider className="my-4"></Divider> */}
                        <div
                          onClick={() => {
                            handleConfirm(batch);
                          }}
                          className="grid grid-cols-5 border-b border-lighter-regent-gray py-2"
                        >
                          <div className="flex col-span-3 whitespace-normal">
                            <div
                              className={`font-poppins font-semibold text-sm w-full whitespace-normal  ${
                                value?.uid === batch.uid
                                  ? 'text-daisy-bush'
                                  : 'text-pickled-bluewood'
                              } `}
                            >
                              {batch.BatchNumber}
                            </div>
                          </div>
                          <div className="uppercase col-span-2 text-right font-semibold">
                            <span className="text-daisy-bush">
                              {batch.QtyLoaded - batch.QtyPutaway} {batch.UOM}
                            </span>
                            <span className="text-regent-gray">
                              {' '}
                              / {batch.QtyLoaded} {batch.UOM}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex justify-center items-center mt-4">
                    <div className="font-poppins font-semibold text-sm text-pickled-bluewood">
                      {listData && Array.isArray(listData)
                        ? 'No data available'
                        : searchTerm && searchTerm.length > 0
                        ? 'No search results found'
                        : 'Loading data. Please wait.'}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : serialModal ? (
            <div
              className="mt-5 px-5 overflow-y-scroll"
              style={{ height: '367px' }}
            >
              {listData && listData?.length !== 0 ? (
                <div>
                  <div className="w-full">
                    {listData.map((batch) => (
                      <div key={batch.uid}>
                        {/* <Divider className="my-4"></Divider> */}
                        <div
                          onClick={() => {
                            handleConfirm(batch);
                          }}
                          className="flex justify-between border-b border-lighter-regent-gray py-2"
                        >
                          <div className="truncate">
                            <div
                              className={`font-poppins whitespace-normal font-semibold text-sm  ${
                                value?.uid === batch.uid
                                  ? 'text-daisy-bush'
                                  : 'text-pickled-bluewood'
                              } `}
                            >
                              #{batch.uid}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex justify-center items-center mt-4">
                    <div className="font-poppins font-semibold text-sm text-pickled-bluewood">
                      {listData && Array.isArray(listData)
                        ? 'No data available'
                        : searchTerm && searchTerm.length > 0
                        ? 'No search results found'
                        : 'Loading data. Please wait.'}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div
              className="overflow-y-auto mx-4 lg:mx-5"
              style={{
                height:
                  showCancelButton === false && showConfirmButton === false
                    ? '80%'
                    : '280px',
              }}
            >
              {handleAdHoc && (
                <div
                  // onClick={() => handleVendorSelect(data?.Id, data?.Value)}
                  onClick={handleAdHoc}
                  className="py-5 border-b-2 border-athens-gray pr-3"
                >
                  <p className="font-poppins font-semibold text-sm text-pickled-bluewood">
                    ########
                  </p>
                  <p className="font-poppins font-medium text-sm text-regent-gray">
                    Ad-hoc
                  </p>
                </div>
              )}

              {listData && listData.length !== 0 ? (
                <>
                  {listData?.map((data) => (
                    <div key={data.Id}>
                      {data?.Id ? (
                        <div
                          onClick={() =>
                            handleVendorSelect(data?.Id, data?.Value)
                          }
                          className=" py-5 border-b-2 border-athens-gray pr-3"
                        >
                          <p className="font-poppins font-semibold text-sm text-pickled-bluewood">
                            {data?.Value}
                          </p>
                          <p className="font-poppins font-medium text-sm text-regent-gray">
                            {data?.Id}
                          </p>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            handleTransferSelect(
                              data?.TransferId,
                              data?.SourceFacilityId,
                              data?.SourceFacilityName,
                              data?.TransferDate
                            )
                          }
                          className="py-5 px-1 border-b-2 border-athens-gray"
                        >
                          <p className="font-poppins font-semibold text-sm text-pickled-bluewood">
                            {data?.TransferId}
                          </p>
                          <p className="font-poppins font-medium text-sm text-regent-gray grid grid-cols-5">
                            <span className="col-span-3">
                              {data?.SourceFacilityName || '-'}
                            </span>
                            <span className="flex col-span-2  justify-start gap-x-2 items-center">
                              <img
                                loading="lazy"
                                src="/images/icons/dot.svg"
                                alt="dot"
                              />
                              {data?.TransferDate
                                ? moment(data?.TransferDate).format(
                                    'MMM DD, YYYY'
                                  )
                                : '-'}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex justify-center items-center mt-4">
                  <div className="font-poppins font-semibold text-sm text-pickled-bluewood">
                    {listData && Array.isArray(listData)
                      ? 'No data available'
                      : searchTerm && searchTerm.length > 0
                      ? 'No search results found'
                      : 'Loading data. Please wait.'}
                  </div>
                </div>
              )}
            </div>
          )}

          {materialPutawayModal ||
          (showCancelButton === false && showConfirmButton === false) ? (
            <></>
          ) : (
            <>
              {/* <div className="grid grid-cols-2 absolute bottom-0 w-full gap-x-4 left-0 px-7 bg-white py-2.5 rounded-b-xl">
                <div
                  onClick={handleCancel}
                  className="font-poppins font-semibold text-sm text-blue-text border border-regent-gray rounded-lg py-4 cursor-pointer flex justify-center"
                >
                  {cancelButtonText ? cancelButtonText : 'Cancel'}
                </div>
                <button
                  onClick={
                    batchModal || serialModal
                      ? () => handleConfirm(value)
                      : handleConfirm
                  }
                  disabled={batchModal || serialModal ? value === null : false}
                  className={`${
                    disabledButton ||
                    (batchModal || serialModal ? value === null : false)
                      ? 'bg-regent-gray'
                      : 'bg-blue-text'
                  } font-poppins font-semibold text-sm rounded-lg text-white`}
                >
                  {confirmButtonText || 'Next'}
                </button>
              </div> */}
            </>
          )}
        </div>
      ) : (
        <div style={modalStyle} className="p-5">
          <div style={{ color: '#8892A5' }}>
            {icon && <img loading="lazy" src={icon} alt="" className="mb-5" />}
            <p
              style={{
                fontSize: 15,
                color: '#383A65',
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 600,
              }}
              className="mb-2"
            >
              {titleText}
            </p>
            <div className="custom-model-content">
              <pre
                style={{
                  fontSize: 13,
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 500,
                }}
              >
                {messageText}
              </pre>
            </div>
          </div>
          <div className="text-right" style={{ marginTop: '22px' }}>
            {showCancelButton && (
              <button
                onClick={handleCancel}
                className={`modal-cancel-btn rounded-md  border-2 body-color2 mr-3 shadow-none py-1 px-3 ${
                  isLoading ? 'opacity-95 pointer-events-none' : ''
                }`}
                style={{
                  borderColor: '#E0E0E3',
                  fontSize: 13,
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 500,
                  height: '35px',
                }}
              >
                {cancelButtonText ? cancelButtonText : 'Cancel'}
              </button>
            )}
            {showConfirmButton !== false && (
              <Button
                loading={isLoading}
                onClick={handleConfirm}
                className="modal-confirm-button theme-color-bg2 border-blue-900 rounded-md border-2"
                style={{
                  fontSize: 13,
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 500,
                  color: '#ffffff',
                  borderColor: '#4f5292',
                  height: '35px',
                }}
              >
                {confirmButtonText}
              </Button>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export function CustomFilterModal(props) {
  const {
    icon,
    titleText,
    messageText,
    isLoading,
    showModal,
    showCancelButton,
    handleCancel,
    confirmButtonText,
    cancelButtonText,
    handleApply,
    zIndex,
    handleClear,
    children,
    appliedFilters,
  } = props;

  const [value, setValue] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Modal
      visible={showModal}
      closable={false}
      centered={true}
      wrapClassName="rounded custom-modal"
      footer={null}
      destroyOnClose={true}
      width={window?.innerWidth < 992 ? '85%' : '414px'}
      bodyStyle={{ width: '100%', padding: 2 }}
      zIndex={zIndex ? zIndex : 1000}
    >
      <div
        style={{ height: window?.innerWidth < 992 ? '92vh' : '80vh' }}
        className="overflow-hidden"
      >
        <div className="flex justify-between items-center my-5 mx-4 lg:mx-5">
          <p
            className="font-poppins font-normal text-xl text-regent-gray"
            style={{ fontSize: '19px' }}
          >
            {titleText}
          </p>
          <div className="h-auto flex items-center">
            <CloseCircleOutlined
              onClick={handleCancel}
              style={{ fontSize: '24px', color: '#8892A5' }}
            />
          </div>
        </div>
        <div className="flex justify-between h-12 px-5 bg-athens-gray items-center font-poppins">
          <p className="text-regent-gray font-medium text-sm">
            {appliedFilters ? `${appliedFilters} applied` : ''}
          </p>
          <div
            onClick={handleClear}
            className="text-blue-text font-medium text-mid cursor-pointer"
          >
            Clear all
          </div>
        </div>
        <div className="px-5 pb-2 overflow-y-auto" style={{ height: '67.5%' }}>
          {children}
          <>
            <div className="grid grid-cols-5 shadow-mob-footer absolute bottom-0 w-full gap-x-2 left-0 px-5 bg-white py-2.5 rounded-b-xl">
              <div
                onClick={handleCancel}
                className="font-poppins font-semibold w-full col-span-2 h-11 items-center text-sm text-regent-gray border border-light-regent rounded-lg py-4 cursor-pointer flex justify-center"
              >
                {cancelButtonText ? cancelButtonText : 'Cancel'}
              </div>
              <button
                onClick={handleApply}
                // disabled={batchModal || serialModal ? value === null : false}
                className={`${'bg-blue-text'} col-span-3 w-full font-poppins font-semibold text-sm rounded-lg h-11 text-white`}
              >
                {confirmButtonText || 'Apply'}
              </button>
            </div>
          </>
        </div>
      </div>
    </Modal>
  );
}
